a {
  color: #eceff1;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.center {
  justify-content: center;
  align-items: center;
}

a.center:hover {
  color: #eceff1;
}

a.left {
  text-decoration: none;
  justify-content: left;
  align-items: center;
}

a.bottom {
  align-items: end;
}

@media only screen and (min-width: 600px) {
  a.left:hover {
      color: #90a4ae;
      color: #bdd4d2
  }
}

@media only screen and (max-width: 600px) {
  a.left:active {
      color: #90a4ae;
      color: #bdd4d2
  }
}

a.moviecard {
  height: 100%;
  border: solid; 
  border-width: 5px;
  border-color: transparent
}

@media only screen and (min-width: 600px) {
  a.moviecard:hover {
      opacity: 0.9;
      border-color: #eceff1
  }
}

@media only screen and (max-width: 600px) {
  a.moviecard {
      border-width: 0.5vw;
  }

  a.moviecard:active {
      opacity: 0.9;
      border-color: #eceff1
  }
}

a.trlogo:hover {
  opacity: 0.85
}

a.list {
  justify-content: left;
  align-items: center;
  color: #90a4ae;
}

@media only screen and (min-width: 600px) {
  a.list:hover {
      color: silver;
  }
}

@media only screen and (max-width: 600px) {
  a.list:active {
      color: silver;
  }
}

a.sublogo {
  display: inline;
}

@media only screen and (max-width: 600px) {
  a.sublogo-mobile {
    display: inline;
    width: auto;
    margin: 0 10px;
  }
}

a.year {
  width: auto
}

a.desc {
  color: rgba(0, 0, 0, 0.87)
}

a.desc:hover {
  color: #90a4ae;
}

a.onllogo {
  display: flex;
  flex: 0 0
}

img.stream {
  border-radius: 50%;
}

.carousel-root, .carousel-slider, .slider-wrapper, .slider ,.animated {
  height: 100%
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: .1
}

.carousel .control-arrow:hover {
  opacity: 1
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0,0,0,0.1);
}

p {
  margin: 0
}

.poem {
  overflow: scroll;
  scrollbar-color: red orange;
  scrollbar-width: thin;
}