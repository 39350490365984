progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
     appearance: none;
    width: 95%;
  }

progress[value]::-webkit-progress-bar {
    background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    border-radius: 15px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 6.5vw;
  }

progress[value]::-webkit-progress-value {
    /* animation: load 3s normal forwards; */
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 15px;
    background: #fff;
    height: 4.9vw;
  }

@media screen and (min-width: 600px) {
    progress[value] {
        /* Reset the default appearance */
        -webkit-appearance: none;
         appearance: none;
        width: 90%;
      }
    
    progress[value]::-webkit-progress-bar {
        background: rgba(255,255,255,0.1);
        justify-content: flex-start;
        border-radius: 15px;
        align-items: center;
        position: relative;
        padding: 0 5px;
        display: flex;
        height: 4.7vw;
      }
    
    progress[value]::-webkit-progress-value {
        /* animation: load 3s normal forwards; */
        box-shadow: 0 10px 40px -10px #fff;
        border-radius: 15px;
        background: #fff;
        height: 3.5vw;
      }
  }

@media screen and (min-width: 960px) {
    progress[value] {
        /* Reset the default appearance */
        -webkit-appearance: none;
         appearance: none;
        width: 80%;
      }
    
    progress[value]::-webkit-progress-bar {
        background: rgba(255,255,255,0.1);
        justify-content: flex-start;
        border-radius: 15px;
        align-items: center;
        position: relative;
        padding: 0 5px;
        display: flex;
        height: 40px;
      }
    
    progress[value]::-webkit-progress-value {
        /* animation: load 3s normal forwards; */
        box-shadow: 0 10px 40px -10px #fff;
        border-radius: 15px;
        background: #fff;
        height: 30px;
      }
  }
  
