#slider {
    margin: auto;
}

#slider .rs-range-color  {
    background-color: #33B5E5;
}
#slider .rs-path-color  {
    background-color: #C2E9F7;
}
#slider .rs-handle  {
    background-color: #C2E9F7;
    padding: 7px;
    border: 2px solid #C2E9F7;
}
#slider .rs-handle.rs-focus  {
    border-color: #33B5E5;
}
#slider .rs-handle:after  {
    border-color: #33B5E5;
    background-color: #33B5E5;
}
#slider .rs-border  {
    border-color: transparent;
}

.rs-tooltip {
    font-size: 30px;
}

@media only screen and (max-width: 960px) {
    .rs-tooltip {
        font-size: 23px;
    }

    #slider .rs-handle-dot {
        padding: 3px;
    }
  }

@media only screen and (max-width: 600px) {
    .rs-tooltip {
        font-size: 16px;
    }

    #slider .rs-handle-dot {
        padding: 0;
    }
  }

